/* eslint-disable default-param-last */
import { takeLatest, call, put } from 'redux-saga/effects';
import { ForbiddenError } from '@avtjs/avt-services';
import { getAccessToken } from '@avtjs/react-components';

import {
  getSystemMessages,
  createSystemMessage as createSystemMessageApi,
  deleteSystemMessage as deleteSystemMessageApi,
} from '../services';

import { handleGlobalError } from './errors';

// Action types

const RESET_STATE = 'admin/sites/RESET_STATE';

const REQUEST_SYSTEM_MESSAGES = 'admin/systemMessages/REQUEST_SYSTEM_MESSAGES';
const RECEIVE_SYSTEM_MESSAGES = 'admin/systemMessages/RECEIVE_SYSTEM_MESSAGES';
const RECEIVE_SYSTEM_MESSAGE = 'admin/systemMessages/RECEIVE_SYSTEM_MESSAGE';

const SUBMIT_SYSTEM_MESSAGE = 'admin/systemMessages/SUBMIT_SYSTEM_MESSAGE';

const DELETE_SYSTEM_MESSAGE = 'admin/systemMessages/DELETE_SYSTEM_MESSAGE';
const DELETE_SUCCESS = 'admin/systemMessages/DELETE_SUCCESS';

// Action creators

export function requestSystemMessages() {
  return {
    type: REQUEST_SYSTEM_MESSAGES,
  };
}

export function receiveSystemMessages(messages) {
  return {
    type: RECEIVE_SYSTEM_MESSAGES,
    messages,
  };
}

export function receiveSystemMessage(message) {
  return {
    type: RECEIVE_SYSTEM_MESSAGE,
    message,
  };
}

export function createSystemMessage(systemMessage) {
  return {
    type: SUBMIT_SYSTEM_MESSAGE,
    systemMessage,
  };
}

export function deleteSystemMessage(id) {
  return {
    type: DELETE_SYSTEM_MESSAGE,
    id,
  };
}

export function deleteSuccess(messageId) {
  return {
    type: DELETE_SUCCESS,
    messageId,
  };
}

// Initial state

const initialState = {
  messages: [],
};

// Reducer

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_STATE: {
      return {
        ...initialState,
      };
    }
    case RECEIVE_SYSTEM_MESSAGES: {
      return {
        ...state,
        messages: action.messages,
      };
    }
    case RECEIVE_SYSTEM_MESSAGE: {
      return {
        ...state,
        messages: [...state.messages, ...[action.message]],
      };
    }
    case DELETE_SUCCESS: {
      return {
        ...state,
        messages: state.messages.filter((m) => m.id !== action.messageId),
      };
    }
    default: {
      return state;
    }
  }
}

// Sagas

function* doRequestSystemMessages() {
  try {
    const token = yield call(getAccessToken, 'auth');
    const { values: messages } = yield getSystemMessages(token);
    yield put(receiveSystemMessages(messages));
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveSystemMessages([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

function* doSubmitSystemMessage({ systemMessage }) {
  try {
    const token = yield call(getAccessToken, 'auth');

    const response = yield createSystemMessageApi(token, systemMessage);
    yield put(receiveSystemMessage(response));
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveSystemMessages([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

function* doDeleteSystemMessage({ id }) {
  try {
    const token = yield call(getAccessToken, 'auth');
    yield deleteSystemMessageApi(token, id);
    yield put(deleteSuccess(id));
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveSystemMessages([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

export const sagas = [
  takeLatest(REQUEST_SYSTEM_MESSAGES, doRequestSystemMessages),
  takeLatest(SUBMIT_SYSTEM_MESSAGE, doSubmitSystemMessage),
  takeLatest(DELETE_SYSTEM_MESSAGE, doDeleteSystemMessage),
];
