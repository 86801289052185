import React from 'react';

class SectionHeader extends React.Component {
  render() {
    const { title } = this.props;

    return (
      <div className="section-header">
        <div className="title">{title}</div>
        <div className="actions">{this.props.children}</div>
      </div>
    );
  }
}

export default SectionHeader;
