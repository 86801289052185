import { Button } from '@avtjs/react-components';
import React from 'react';

const SystemMessageItem = ({ message, onDelete }) => (
  <div className="system-message-item">
    <Button
      slim
      activity="danger"
      onClick={() => onDelete(message.id)}
    >
      {' '}
      Delete{' '}
    </Button>
    <div style={{ marginBottom: 10 }}>{message.title}</div>
    <div dangerouslySetInnerHTML={{ __html: message.body }} />
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <strong>Active from: </strong>
      {message.activeFrom && new Date(message.activeFrom).toString()}
      {!message.activeFrom && 'Always'}
    </div>
    <div>
      <strong>Active until: </strong>
      {message.activeUntil && new Date(message.activeUntil).toString()}
      {!message.activeUntil && 'Always'}
    </div>
  </div>
);

export default SystemMessageItem;
