import React, { useState } from 'react';
import { Button } from '@avtjs/react-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { DatePicker, TimePicker } from '../DateTimePicker';
import Modal from '../Modal';

const SystemMessageModal = ({ onCloseModal, onCreateMessage }) => {
  const [message, setMessage] = useState({});
  const [activeFromEnabled, setActiveFromEnabled] = useState(false);
  const [activeUntilEnabled, setActiveUntilEnabled] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState('00:00:00');
  const [endDate, setEndDate] = useState(new Date());
  const [endTime, setEndTime] = useState('23:59:59');

  const formatDateTime = (date, time) => {
    const [hours, minutes, seconds] = time.split(':');
    return new Date(date).setHours(hours, minutes, seconds);
  };

  const onSubmitForm = (e) => {
    const messageToPost = {
      ...message,
    };

    if (activeFromEnabled) {
      messageToPost.activeFrom = formatDateTime(startDate, startTime);
    }
    if (activeUntilEnabled) {
      messageToPost.activeUntil = formatDateTime(endDate, endTime);
    }

    e.preventDefault();

    onCreateMessage(messageToPost);
  };

  return (
    <Modal
      isOpen={true}
      onClose={onCloseModal}
      title="Add system message"
    >
      <section className="system-messages-modal">
        <form
          className="system-message-form"
          onSubmit={onSubmitForm}
        >
          <div className="field">
            <label className="system-messages--modal-field">
              <span>Title</span>
              <input
                name="title"
                type="text"
                required
                onChange={(e) => setMessage({ ...message, title: e.target.value })}
              />
            </label>
          </div>
          <div className="field">
            <label className="system-messages--modal-field">
              <span>Body</span>
              <textarea
                name="body"
                required
                onChange={(e) => setMessage({ ...message, body: e.target.value })}
              ></textarea>
            </label>
          </div>
          {activeFromEnabled && (
            <div className="date-time-field">
              <label htmlFor="startDate">
                <span>Active From</span>
              </label>
              <div className="datepickers">
                <DatePicker
                  id={'startDate'}
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                />
                <TimePicker
                  withSeconds
                  value={startTime}
                  onChange={(time) => setStartTime(time)}
                />
              </div>
            </div>
          )}
          {!activeFromEnabled && (
            <Button
              slim
              activity="secondary"
              onClick={() => setActiveFromEnabled(true)}
            >
              {' '}
              Add start datetime{' '}
            </Button>
          )}
          {activeUntilEnabled && (
            <div className="date-time-field">
              <label htmlFor="endDate">
                <span> Active Until </span>
              </label>
              <div className="datepickers">
                <DatePicker
                  id={'endDate'}
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                />
                <TimePicker
                  withSeconds
                  value={endTime}
                  onChange={(time) => setEndTime(time)}
                />
              </div>
            </div>
          )}
          {!activeUntilEnabled && (
            <Button
              slim
              activity="secondary"
              onClick={() => setActiveUntilEnabled(true)}
            >
              {' '}
              Add end datetime{' '}
            </Button>
          )}
          <div className="submit">
            <Button>Create message</Button>
          </div>
        </form>

        <h2>Message preview:</h2>

        <div className="system-message-preview-item">
          <strong> {message.title} </strong>
          <div dangerouslySetInnerHTML={{ __html: message.body }}></div>
        </div>
      </section>
    </Modal>
  );
};

export default SystemMessageModal;
