import React from 'react';

import ProfilePhoto from '../ProfilePhoto';

class UserBadge extends React.Component {
  render() {
    const { user } = this.props;

    return (
      <div className="user-badge-component">
        <ProfilePhoto />
        <span className="title">{user.displayableId}</span>
      </div>
    );
  }
}

export default UserBadge;
