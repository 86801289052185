import React from 'react';
import { Link } from 'react-router-dom';

class Entity extends React.Component {
  render() {
    const { eref } = this.props;

    return (
      <span className="entity">
        <Link to={`/entities/${eref}`}>{eref}</Link>
      </span>
    );
  }
}

export default Entity;
