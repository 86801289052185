import { takeLatest, call, put } from 'redux-saga/effects';
import { ForbiddenError } from '@avtjs/avt-services';
import { getAccessToken } from '@avtjs/react-components';

import { createServiceClient } from '../services';
import { encodeScope } from '../utils';

import { handleGlobalError } from './errors';

// Action types

const CONNECT = 'admin/entities/CONNECT';
const DISCONNECT = 'admin/entities/DISCONNECT';

const GRANT_PERMISSION = 'admin/entities/GRANT_PERMISSION';
const REVOKE_PERMISSION = 'admin/entities/REVOKE_PERMISSION';

const REQUEST_ENTITY = 'admin/entities/REQUEST_ENTITY';
export const RECEIVE_ENTITY = 'admin/entities/RECEIVE_ENTITY';

const REQUEST_ENTITIES = 'admin/entities/REQUEST_ENTITIES';
export const RECEIVE_ENTITIES = 'admin/entities/RECEIVE_ENTITIES';

// Action creators

export function requestEntities(etype) {
  return {
    type: REQUEST_ENTITIES,
    etype,
  };
}

export function receiveEntities(etype, entities) {
  return {
    type: RECEIVE_ENTITIES,
    etype,
    entities,
  };
}

export function requestEntity(etype, eid) {
  return {
    type: REQUEST_ENTITY,
    etype,
    eid,
  };
}

export function receiveEntity(etype, eid, entity) {
  return {
    type: RECEIVE_ENTITY,
    etype,
    eid,
    entity,
  };
}

export function grantPermission(etype, eid, permissions) {
  return {
    type: GRANT_PERMISSION,
    etype,
    eid,
    permissions,
  };
}

export function revokePermission(etype, eid, permissions) {
  return {
    type: REVOKE_PERMISSION,
    etype,
    eid,
    permissions,
  };
}

export function addConnection(etype, eid, target) {
  return {
    type: CONNECT,
    etype,
    eid,
    target,
  };
}

export function removeConnection(etype, eid, target) {
  return {
    type: DISCONNECT,
    etype,
    eid,
    target,
  };
}

// Initial state
const initialState = {
  entity: null,
};

// Reducer
export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_ENTITIES: {
      const { etype, entities } = action;
      return {
        ...state,
        [etype]: entities,
      };
    }
    case RECEIVE_ENTITY: {
      const { etype, eid, entity } = action;
      return {
        ...state,
        // TODO: Just store as current entity and reset on unmount.
        [`${etype}/${eid}`]: entity,
      };
    }

    default: {
      return state;
    }
  }
}

// Sagas

function* doRequestEntities(action) {
  const { etype } = action;

  try {
    const token = yield call(getAccessToken, 'auth');
    const client = createServiceClient('auth', token);
    const { values } = yield call([client.auth, client.auth.getEntities], etype);
    yield put(receiveEntities(etype, values));
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveEntities(etype, []));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

function* doRequestEntity(action) {
  try {
    const { etype, eid } = action;

    const token = yield call(getAccessToken, 'auth');
    const client = createServiceClient('auth', token);
    const entity = yield call([client.auth, client.auth.getEntity], etype, eid);
    yield put(receiveEntity(etype, eid, entity));
  } catch (err) {
    yield put(handleGlobalError(err));
  }
}

function* doGrantPermission(action) {
  try {
    const { etype, eid, permissions } = action;

    const encodedPermissions = {};
    Object.keys(permissions).forEach((permission) => {
      encodedPermissions[permission] = [encodeScope(permissions[permission])];
    });

    const token = yield call(getAccessToken, 'auth');
    const client = createServiceClient('auth', token);
    const entity = yield call(
      [client.auth, client.auth.grant],
      `${etype}/${eid}`,
      encodedPermissions
    );
    yield put(receiveEntity(etype, eid, entity));
  } catch (err) {
    yield put(handleGlobalError(err));
  }
}

function* doRevokePermission(action) {
  try {
    const { etype, eid, permissions } = action;

    const token = yield call(getAccessToken, 'auth');
    const client = createServiceClient('auth', token);
    const entity = yield call([client.auth, client.auth.revoke], `${etype}/${eid}`, permissions);
    yield put(receiveEntity(etype, eid, entity));
  } catch (err) {
    yield put(handleGlobalError(err));
  }
}

function* doConnect(action) {
  try {
    const { etype, eid, target } = action;

    const token = yield call(getAccessToken, 'auth');
    const client = createServiceClient('auth', token);

    yield call([client.auth, client.auth.add], `${etype}/${eid}`, [target]);
    const entity = yield call([client.auth, client.auth.getEntity], etype, eid);

    yield put(receiveEntity(etype, eid, entity));
  } catch (err) {
    yield put(handleGlobalError(err));
  }
}

function* doDisconnect(action) {
  try {
    const { etype, eid, target } = action;

    const token = yield call(getAccessToken, 'auth');
    const client = createServiceClient('auth', token);

    yield call([client.auth, client.auth.remove], `${etype}/${eid}`, [target]);
    const entity = yield call([client.auth, client.auth.getEntity], etype, eid);

    yield put(receiveEntity(etype, eid, entity));
  } catch (err) {
    yield put(handleGlobalError(err));
  }
}

export const sagas = [
  takeLatest(CONNECT, doConnect),
  takeLatest(DISCONNECT, doDisconnect),
  takeLatest(REVOKE_PERMISSION, doRevokePermission),
  takeLatest(GRANT_PERMISSION, doGrantPermission),
  takeLatest(REQUEST_ENTITIES, doRequestEntities),
  takeLatest(REQUEST_ENTITY, doRequestEntity),
];
