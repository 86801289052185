import React from 'react';

class EmptyState extends React.Component {
  render() {
    return (
      <div className="empty-state-component">
        <div className="content">{this.props.children}</div>
      </div>
    );
  }
}

export default EmptyState;
