import React from 'react';
import { connect } from 'react-redux';

import { decodeScope } from '../../utils';
import { requestScope } from '../../bundles/scopes';
import ScopeGrantsTable from '../ScopeGrantsTable';
import PageHeader from '../PageHeader';
import BackLink from '../BackLink';

class ScopeDetail extends React.Component {
  componentDidMount() {
    const { scope, scopeRef } = this.props;

    if (scope === null) {
      this.props.requestScope(scopeRef);
    }
  }

  render() {
    const { scope, scopeRef } = this.props;

    if (scope === null) {
      return 'loading ...';
    }

    return (
      <section>
        <BackLink
          title="Back to Scopes"
          to="/scopes"
        />

        <PageHeader title={JSON.stringify(decodeScope(scopeRef))} />

        <ScopeGrantsTable grants={scope.grants} />
      </section>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { scopeRef } = props.match.params;
  return {
    scopeRef,
    scope: state.scopes[scopeRef] || null,
  };
};

const mapDispatchToProps = {
  requestScope,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScopeDetail);
