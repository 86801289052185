import { all } from 'redux-saga/effects';

import { sagas as auth } from './bundles/auth';
import { sagas as errors } from './bundles/errors';
import { sagas as entities } from './bundles/entities';
import { sagas as scopes } from './bundles/scopes';
import { sagas as ad } from './bundles/ad';
import { sagas as sites } from './bundles/sites';
import { sagas as sources } from './bundles/sources';
import { sagas as components } from './bundles/components';
import { sagas as models } from './bundles/models';
import { sagas as layouts } from './bundles/layouts';
import { sagas as properties } from './bundles/properties';
import { sagas as systemMessages } from './bundles/systemMessages';

function* watchAll() {
  yield all([
    ...auth,
    ...errors,
    ...entities,
    ...scopes,
    ...ad,
    ...sites,
    ...sources,
    ...components,
    ...models,
    ...layouts,
    ...properties,
    ...systemMessages,
  ]);
}

export default watchAll;
