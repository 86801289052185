const css = `.site-detail .site-detail-items {
  margin: 0;
  padding: 0;
}
.site-detail .site-detail-items li {
  margin-bottom: 0.8rem;
}
.site-detail .site-detail-items li span:first-child {
  font-weight: bolder;
}
.site-detail .site-detail-subitems {
  margin-top: 0.5rem;
  margin-left: 1rem;
}
.site-detail .site-detail-subitems li {
  margin-bottom: 1rem;
}
.site-detail .site-detail--actions {
  margin-top: 0.2rem;
}
.site-detail .site-detail--actions button {
  margin-right: 0.4rem;
  padding: 0.2rem 0.4rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU291cmNlRGV0YWlsIiwic291cmNlcyI6WyJTb3VyY2VEZXRhaWwuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFHRTtFQUNFO0VBQ0E7O0FBRUE7RUFDRTs7QUFFQTtFQUNFOztBQUlOO0VBQ0U7RUFDQTs7QUFFQTtFQUNFOztBQUlKO0VBQ0U7O0FBRUE7RUFDRTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCBcIkBhdnRqcy9yZWFjdC1jb21wb25lbnRzL2Rpc3QvY29sb3JzLnNjc3NcIjtcblxuLnNpdGUtZGV0YWlsIHtcbiAgLnNpdGUtZGV0YWlsLWl0ZW1zIHtcbiAgICBtYXJnaW46IDA7XG4gICAgcGFkZGluZzogMDtcblxuICAgIGxpIHtcbiAgICAgIG1hcmdpbi1ib3R0b206IDAuOHJlbTtcblxuICAgICAgc3BhbjpmaXJzdC1jaGlsZCB7XG4gICAgICAgIGZvbnQtd2VpZ2h0OiBib2xkZXI7XG4gICAgICB9XG4gICAgfVxuICB9XG4gIC5zaXRlLWRldGFpbC1zdWJpdGVtcyB7XG4gICAgbWFyZ2luLXRvcDogMC41cmVtO1xuICAgIG1hcmdpbi1sZWZ0OiAxcmVtO1xuXG4gICAgbGkge1xuICAgICAgbWFyZ2luLWJvdHRvbTogMXJlbTtcbiAgICB9XG4gIH1cblxuICAuc2l0ZS1kZXRhaWwtLWFjdGlvbnMge1xuICAgIG1hcmdpbi10b3A6IDAuMnJlbTtcblxuICAgIGJ1dHRvbiB7XG4gICAgICBtYXJnaW4tcmlnaHQ6IDAuNHJlbTtcbiAgICAgIHBhZGRpbmc6IDAuMnJlbSAwLjRyZW07XG4gICAgfVxuICB9XG59XG4iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
