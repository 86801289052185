import { createRoot } from 'react-dom/client';

import React from 'react'; // eslint-disable-line no-unused-vars
import Modal from 'react-modal';
import '../scss/main.scss';

let configPath;
if (process.env.NODE_ENV === 'production' && process.env.DEPLOY_PREVIEW === false) {
  configPath = `/config.${btoa(Date.now()).replace(/=/g, '')}.json`;
} else {
  configPath = '/config.json';
}

fetch(configPath)
  .then((res) => res.json())
  .then(async (config) => {
    window.config = config;
    // For the sake of satisfying the author of global-cache module:
    // https://github.com/ljharb/global-cache/issues/47#issuecomment-991976187
    window.global = window;

    const App = (await import('./components/App')).default;
    const root = createRoot(document.getElementById('app'));
    Modal.setAppElement('#app');
    root.render(<App />);
  });
