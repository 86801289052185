import React from 'react';
import { validateScope } from '@avtjs/avt-services';
import { Button } from '@avtjs/react-components';

import Modal from '../Modal';
import Scope from '../Scope';

class PermissionModal extends React.Component {
  state = {
    scopeError: false,
    scope: '',
    permission: null,
    permissions: [],
  };

  componentWillReceiveProps(props) {
    this.setState({
      permission: props.permission,
    });
  }

  handleRemove = (permission) => () => {
    const { key } = this.state.permission;
    this.props.onClickRemovePermission(key, permission);
  };

  handleAddScope = (event) => {
    event.preventDefault();
    this.setState({ scopeError: false });

    if (!validateScope(this.state.scope)) {
      this.setState({ scopeError: true });
    } else {
      this.props.onClickAddPermission({
        key: this.state.permission.key,
        value: this.state.scope,
      });

      this.setState({
        scope: '',
      });
    }
  };

  render() {
    if (this.state.permission) {
      return (
        <Modal
          isOpen={this.props.isOpen}
          onClose={this.props.onClickClose}
          title={this.props.title}
        >
          <section className="permissions-modal">
            <section className="permissions-value-list">
              <div className="permission-values-header">
                <strong>{this.state.permission.key}</strong>
              </div>
              {(this.props.perms[this.state.permission.key] || []).map((scopeRef) => (
                <div
                  className="permission-value-list-item"
                  key={scopeRef}
                >
                  <div>
                    <Scope scopeRef={scopeRef} />
                  </div>
                  <div>
                    <Button
                      activity="danger"
                      design="outlined"
                      onClick={this.handleRemove(scopeRef)}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              ))}
            </section>
            <form
              className="add-permission-form"
              onSubmit={this.handleAddScope}
            >
              <div className="field">
                <input
                  type="text"
                  placeholder="Enter JSON scope ..."
                  value={this.state.scope}
                  onChange={(e) => this.setState({ scope: e.target.value })}
                  required
                />
                {this.state.scopeError && (
                  <div className="error">
                    Invalid scope (must be JSON object, all keys strings, only one level of nesting)
                  </div>
                )}
              </div>
              <div className="submit">
                <Button activity="action">Add scope</Button>
              </div>
            </form>
          </section>
        </Modal>
      );
    }
    return <div> </div>;
  }
}

export default PermissionModal;
