import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@avtjs/react-components';

import { requestModels } from '../../bundles/models';
import { submitSite } from '../../bundles/sites';

import Modal from '../Modal';

const SiteModelSelector = ({
  isLoading,
  data,
  models,
  error,
  submitSuccess,
  onSubmitSuccess,
  onClose,
  requestModels: getModels,
  submitSite: submitSiteData,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedModel, setSelectedModel] = useState(data.model, '');
  const [selectedVersion, setSelectedVersion] = useState(data.version, '');

  useEffect(() => {
    getModels();
  }, []);

  const submit = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      const site = { ...data, model: selectedModel, version: selectedVersion };

      if (site.org === '') delete site.org;
      if (site.customer === '') delete site.customer;

      submitSiteData(site);
    }
  };

  if (submitSuccess !== null) {
    onSubmitSuccess(submitSuccess);
  }

  if (error !== null && error.length > 0 && isSubmitting) {
    setIsSubmitting(false);
  }

  let filteredModels = [];
  if (!isLoading && models && models.length && data && data.org) {
    filteredModels = [...models].filter((item) => item.org === data.org && !item.removed);
    filteredModels = filteredModels.map((modelItem) => {
      const nextModel = { ...modelItem };
      nextModel.versions = modelItem.versions.filter(
        (versionItem) => String(versionItem.status).toLowerCase() === 'completed'
      );
      return nextModel;
    });
    filteredModels = filteredModels.filter((item) => item.versions && item.versions.length > 0);
    filteredModels = filteredModels.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  let modelVersions = [];
  if (filteredModels.length) {
    const modelVersionsArr = filteredModels.filter((model) => model.id === selectedModel);
    if (modelVersionsArr.length) {
      modelVersions = (modelVersionsArr[0] || {}).versions;
    }
  }

  const canSubmit = Boolean(
    modelVersions.length > 0 && selectedModel.length > 0 && selectedVersion.length > 0
  );

  return (
    <Modal
      isOpen
      style="slim"
      onClose={onClose}
      title="Select model"
    >
      <section className="site-model-selector">
        {error !== null && error.length > 0 && (
          <div className="sites--modal-message">
            {error.length > 0 && error.map((msg) => <p key={msg}>{msg}</p>)}
          </div>
        )}
        <div>
          {isLoading && <p>Loading...</p>}
          {!isLoading && filteredModels.length === 0 && (
            <p className="model-selector-message">No models for this org...</p>
          )}
          {!isLoading && filteredModels.length > 0 && (
            <React.Fragment>
              <label htmlFor="model">Model: </label>
              <select
                name="model"
                className="model-selector-input"
                value={selectedModel}
                onChange={(e) => {
                  setSelectedVersion('');
                  setSelectedModel(e.target.value);
                }}
              >
                <option
                  value=""
                  disabled
                >
                  --- click here to select ---
                </option>
                {filteredModels.map((item) => (
                  <option
                    key={`model-${item.id}`}
                    value={item.id}
                  >
                    {item.name}
                  </option>
                ))}
              </select>
            </React.Fragment>
          )}
          <div className="model-selector-input-spacer"></div>
          {!isLoading && selectedModel.length > 0 && modelVersions.length === 0 && (
            <p className="model-selector-message">
              No version for this model(try a different one)...
            </p>
          )}
          {!isLoading && selectedModel.length > 0 && modelVersions.length > 0 && (
            <React.Fragment>
              <label htmlFor="model-version">Version: </label>
              <select
                name="model-version"
                className="model-selector-input"
                value={selectedVersion}
                onChange={(e) => setSelectedVersion(e.target.value)}
              >
                <option
                  value=""
                  disabled
                >
                  --- click here to select ---
                </option>
                {modelVersions.map((item) => (
                  <option
                    key={`modelVersion-${item.id}`}
                    value={item.id}
                  >
                    {item.versionNumber}
                  </option>
                ))}
              </select>
            </React.Fragment>
          )}
        </div>
        <br></br>
        <Button
          onClick={submit}
          disabled={!canSubmit}
        >
          Assign model
        </Button>
      </section>
    </Modal>
  );
};

function mapStateToProps({
  models: { isLoading, modelsList },
  errors: { error },
  sites: { submitSuccess },
}) {
  let messages = [];
  if (error && error !== null && error.message) {
    messages = [`${error.message.message}: `];
    if (
      error.errors &&
      error.errors.errors &&
      error.errors.errors.body &&
      error.errors.errors.body.length
    ) {
      messages = [...messages, ...error.errors.errors.body.map((item) => item.message)];
    }
  }
  return {
    isLoading,
    models: modelsList,
    error: messages.length ? messages : '',
    submitSuccess,
  };
}

const mapDispatchToProps = {
  requestModels,
  submitSite,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteModelSelector);
