import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { resetSourceState, requestSources } from '../../bundles/sources';
import SearchForm from '../SearchForm';
import SourceTable from '../SourceTable';
import PageHeader from '../PageHeader';

const SourceList = ({
  etype,
  sources,
  sourcesIsLoading,
  resetSourceState: resetState,
  requestSources: getSources,
}) => {
  useEffect(() => {
    getSources(etype);
  }, [etype]);

  if (sourcesIsLoading) {
    return 'Loading ...';
  }

  const onDelete = () => {
    setTimeout(() => {
      resetState();
      getSources(etype);
    }, 300);
  };

  return (
    <section>
      <PageHeader title={'Sources'}>
        <SearchForm
          placeHolder="Source id ..."
          redirectUri={(term) => `/sources/${term}`}
        />
      </PageHeader>
      <SourceTable
        sources={sources}
        onDelete={onDelete}
      />
    </section>
  );
};

function mapStateToProps({ sources }) {
  return {
    sources: sources.sourcesList,
    sitesIsLoading: sources.isLoading,
  };
}

const mapDispatchToProps = {
  requestSources,
  resetSourceState,
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceList);
