/* eslint-disable default-param-last */
import { takeLatest, call, put } from 'redux-saga/effects';
import { ForbiddenError } from '@avtjs/avt-services';
import { getAccessToken } from '@avtjs/react-components';

import {
  getComponents,
  createComponent,
  updateComponent,
  deleteComponent,
  createVariable,
  deleteVariable,
  updateVariable,
} from '../services';

import { handleGlobalError } from './errors';

// Action types
const REQUEST_COMPONENTS = 'admin/components/REQUEST_COMPONENTS';
const RECEIVE_COMPONENTS = 'admin/components/RECEIVE_COMPONENTS';

const REQUEST_COMPONENT = 'admin/components/REQUEST_COMPONENT';
const RECEIVE_COMPONENT = 'admin/components/RECEIVE_COMPONENT';

const SUBMIT_COMPONENT = 'admin/components/SUBMIT_COMPONENT';
const SUBMIT_SUCCESS = 'admin/components/SUBMIT_SUCCESS';

const DELETE_COMPONENT = 'admin/components/DELETE_COMPONENT';
const DELETE_SUCCESS = 'admin/components/DELETE_SUCCESS';

const DELETE_VARIABLE = 'admin/components/DELETE_VARIABLE';
const DELETE_VARIABLE_SUCCESS = 'admin/components/DELETE_VARIABLE_SUCCESS';

const SUBMIT_VARIABLE = 'admin/components/SUBMIT_VARIABLE';
const SUBMIT_VARIABLE_SUCCESS = 'admin/components/SUBMIT_VARIABLE_SUCCESS';

// Action creators
export function requestComponents(filter) {
  return {
    type: REQUEST_COMPONENTS,
    filter,
  };
}

export function receiveComponents(components) {
  return {
    type: RECEIVE_COMPONENTS,
    components,
  };
}

export function requestComponent(id) {
  return {
    type: REQUEST_COMPONENT,
    id,
  };
}

export function receiveComponent(component) {
  return {
    type: RECEIVE_COMPONENT,
    component,
  };
}

export function submitComponent(component) {
  return {
    type: SUBMIT_COMPONENT,
    component,
  };
}

export function submitSuccess(id) {
  return {
    type: SUBMIT_SUCCESS,
    id,
  };
}

export function removeComponent(id) {
  return {
    type: DELETE_COMPONENT,
    id,
  };
}

export function removeVariable({ componentId, variableId }) {
  return {
    type: DELETE_VARIABLE,
    componentId,
    variableId,
  };
}

export function submitVariable(data) {
  return {
    type: SUBMIT_VARIABLE,
    data,
  };
}

export function submitVariableSuccess(id) {
  return {
    type: SUBMIT_VARIABLE_SUCCESS,
    id,
  };
}

export function deleteSuccess(id) {
  return {
    type: DELETE_SUCCESS,
    id,
  };
}

export function deleteVariableSuccess(id) {
  return {
    type: DELETE_VARIABLE_SUCCESS,
    id,
  };
}

// Initial state
const initialState = {
  components: {},
  isLoading: false,
  submitSuccess: null,
  deleteSuccess: null,
  deleteVariableSuccess: null,
};

// Reducer
export function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_COMPONENTS: {
      return {
        ...state,
        components: [],
        isLoading: true,
      };
    }
    case REQUEST_COMPONENT: {
      return {
        ...state,
        component: null,
        isLoading: true,
      };
    }
    case RECEIVE_COMPONENTS: {
      const stateComponents = state.components;
      action.components.forEach((component) => {
        stateComponents[component.id] = component;
      });

      return {
        ...state,
        components: stateComponents,
        isLoading: false,
      };
    }
    case RECEIVE_COMPONENT: {
      const { component } = action;
      return {
        ...state,
        components: {
          ...state.components,
          [component.id]: component,
        },
        isLoading: false,
      };
    }
    case SUBMIT_COMPONENT: {
      return {
        ...state,
        submitSuccess: null,
      };
    }
    case SUBMIT_SUCCESS: {
      return {
        ...state,
        submitSuccess: action.id,
      };
    }
    case DELETE_COMPONENT: {
      return {
        ...state,
        deleteSuccess: false,
      };
    }
    case DELETE_SUCCESS: {
      const { id } = action;
      const updatedComponents = state.components;
      delete updatedComponents[id];
      return {
        ...state,
        components: updatedComponents,
        deleteSuccess: true,
      };
    }
    case SUBMIT_VARIABLE: {
      return {
        ...state,
        submitVariableSuccess: null,
      };
    }
    case SUBMIT_VARIABLE_SUCCESS: {
      return {
        ...state,
        submitVariableSuccess: action.id,
      };
    }
    case DELETE_VARIABLE: {
      return {
        ...state,
        deleteVariableSuccess: false,
      };
    }
    case DELETE_VARIABLE_SUCCESS: {
      return {
        ...state,
        deleteVariableSuccess: true,
      };
    }
    default: {
      return state;
    }
  }
}

// Selectors
export const getComponentsBySite = ({ components: { components } }, siteId) =>
  Object.values(components).filter(({ site }) => site === siteId);

export const getComponentsBySource = ({ components: { components } }, sourceId) =>
  Object.values(components).filter(({ source }) => source === sourceId);

// Sagas
function* doRequestComponents(action) {
  try {
    const { filter } = action;
    const token = yield call(getAccessToken);
    const { values: components } = yield getComponents(token, filter);
    yield put(receiveComponents(components));
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveComponents([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

function* doSubmitComponent({ component }) {
  try {
    const token = yield call(getAccessToken);
    let response;
    if (component.id) {
      response = yield updateComponent(token, component);
    } else {
      response = yield createComponent(token, component);
    }
    yield put(submitSuccess(response.id));
    yield put(receiveComponents([response]));
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveComponents([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

function* doDeleteComponent({ id }) {
  try {
    const token = yield call(getAccessToken);
    yield deleteComponent(token, id);
    yield put(deleteSuccess(id));
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveComponents([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

function* doDeleteVariable({ componentId, variableId }) {
  try {
    const token = yield call(getAccessToken);
    const response = yield deleteVariable(token, componentId, variableId);
    yield put(receiveComponent({ ...response, source: response.source.id }));
    yield put(deleteVariableSuccess(variableId));
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      // yield put(receiveComponents([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

function* doSubmitVariable({ data }) {
  try {
    const token = yield call(getAccessToken);
    let response;
    if (data.id) {
      const { componentId } = data;
      // eslint-disable-next-line no-param-reassign
      delete data.componentId;
      response = yield updateVariable(token, componentId, data);
    } else {
      response = yield createVariable(token, data);
    }

    yield put(submitVariableSuccess(response.id));
    yield put(receiveComponent({ ...response, source: response.source.id }));
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveComponents([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

export const sagas = [
  takeLatest(REQUEST_COMPONENTS, doRequestComponents),
  takeLatest(SUBMIT_COMPONENT, doSubmitComponent),
  takeLatest(DELETE_COMPONENT, doDeleteComponent),
  takeLatest(SUBMIT_VARIABLE, doSubmitVariable),
  takeLatest(DELETE_VARIABLE, doDeleteVariable),
];
