import React from 'react';
import { connect } from 'react-redux';

import { requestEntities } from '../../bundles/entities';
import SearchForm from '../SearchForm';
import EntityTable from '../EntityTable';
import PageHeader from '../PageHeader';

class EntityList extends React.Component {
  componentDidMount() {
    const { etype } = this.props;
    this.props.requestEntities(etype);
  }

  render() {
    const { etype, entities } = this.props;

    if (typeof entities === 'undefined') {
      return 'Loading ...';
    }

    return (
      <section>
        <PageHeader title={`Entity: ${etype}`}>
          <SearchForm
            placeHolder="Entity id ..."
            redirectUri={(param) => `/entities/${etype}/${param}`}
          />
        </PageHeader>

        <EntityTable
          entities={entities}
          etype={etype}
        />
      </section>
    );
  }
}

function mapStateToProps(state, props) {
  const { etype } = props.match.params;
  return {
    etype,
    entities: state.entities[etype],
  };
}

const mapDispatchToProps = {
  requestEntities,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityList);
