import React from 'react';

import EmptyState from '../EmptyState';
import SectionHeader from '../SectionHeader';
import Permission from '../Permission';
import Scope from '../Scope';

export default class PermissionTable extends React.Component {
  render() {
    const { eid, permissions } = this.props;

    return (
      <section className="permissions-table">
        <SectionHeader title="All permissions" />

        {Object.keys(permissions).length ? (
          <table>
            <thead>
              <tr>
                <th>Permission</th>
                <th>Scopes</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(permissions)
                .sort()
                .map((permission) => (
                  <tr key={permission}>
                    <td className="permission-name">
                      <Permission name={permission} />
                    </td>
                    <td>
                      {permissions[permission].map((scopeRef) => (
                        <Scope
                          key={`${permission}-${scopeRef}`}
                          scopeRef={scopeRef}
                        />
                      ))}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <EmptyState>{eid} doesn't have any permissions.</EmptyState>
        )}
      </section>
    );
  }
}
