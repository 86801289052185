import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Can } from '@avtjs/react-components';

import {
  requestSystemMessages,
  createSystemMessage,
  deleteSystemMessage,
} from '../../bundles/systemMessages';
import SystemMessageItem from '../SystemMessageItem';
import PageHeader from '../PageHeader';
import SectionHeader from '../SectionHeader';
import SystemMessageModal from '../SystemMessageModal';

const SystemMessageList = () => {
  const [displayModal, setDisplayModal] = useState(false);
  const dispatch = useDispatch();
  const systemMessages = useSelector((state) => state.systemMessages.messages);

  useEffect(() => {
    dispatch(requestSystemMessages());
  }, []);

  const onCreateMessage = (message) => {
    dispatch(createSystemMessage(message));
    setDisplayModal(false);
  };

  const onDeleteMessage = (id) => {
    dispatch(deleteSystemMessage(id));
  };

  return (
    <section>
      <PageHeader title={'System messages'}></PageHeader>
      <SectionHeader title="Messages">
        <Can permission="systemMessages/Write">
          <Button
            activity="info"
            onClick={() => setDisplayModal(true)}
          >
            Add message
          </Button>
        </Can>
      </SectionHeader>
      {systemMessages.map((message) => (
        <SystemMessageItem
          onDelete={onDeleteMessage}
          message={message}
          key={message.id}
        />
      ))}
      {displayModal && (
        <SystemMessageModal
          onCloseModal={() => setDisplayModal(false)}
          onCreateMessage={onCreateMessage}
        />
      )}
    </section>
  );
};

export default SystemMessageList;
