import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@avtjs/react-components';

import EmptyState from '../EmptyState';
import Scope from '../Scope';

class ScopeTable extends React.Component {
  render() {
    const { scopes } = this.props;

    return (
      <section className="scope-table">
        {scopes.length ? (
          <table>
            <thead>
              <tr>
                <th colSpan="2">Scope</th>
              </tr>
            </thead>
            <tbody>
              {scopes.sort().map((scope) => (
                <tr key={scope}>
                  <td>
                    <Scope scopeRef={scope} />
                  </td>
                  <td className="actions">
                    <Link to={`/scopes/${scope}`}>
                      <Button
                        activity="secondary"
                        design="outlined"
                      >
                        View
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <EmptyState>Nothing to display.</EmptyState>
        )}
      </section>
    );
  }
}

export default ScopeTable;
