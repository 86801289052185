import React from 'react';
import { Link } from 'react-router-dom';

import { decodeScope } from '../../utils';

class Scope extends React.Component {
  render() {
    const { scopeRef } = this.props;

    const scope = JSON.stringify(decodeScope(scopeRef || '()'));

    return (
      <span className="scope-component">
        <Link
          to={`/scopes/${scopeRef}`}
          title={scopeRef}
        >
          {scope}
        </Link>
      </span>
    );
  }
}

export default Scope;
