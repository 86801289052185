import React from 'react';

import { Link } from 'react-router-dom';

class BackLink extends React.Component {
  render() {
    return (
      <div className="back-link">
        <Link to={this.props.to}>{this.props.title}</Link>
      </div>
    );
  }
}

export default BackLink;
