import React from 'react';
import BaseAutocomplete from 'react-autocomplete';
import classnames from 'classnames';

const localProps = {
  wrapperStyle: { display: 'block' },
  className: 'autocomplete-input',
  renderItem: (item, isHighlighted) => {
    const classes = classnames('autocomplete-suggestion', {
      highlighted: isHighlighted,
    });
    return (
      <div
        key={item}
        className={classes}
      >
        {' '}
        {item}{' '}
      </div>
    );
  },
  renderMenu: (items) => (
    <div
      className="menu"
      children={items}
    />
  ),
};

class Autocomplete extends React.Component {
  render() {
    const props = { ...localProps, ...this.props };
    return (
      <div className="autocomplete-component">
        <BaseAutocomplete {...props} />
      </div>
    );
  }
}

export default Autocomplete;
