const css = `.profile-component .permissions {
  display: table;
}
.profile-component .permissions .permission {
  display: table-row;
}
.profile-component .permissions .permission .name,
.profile-component .permissions .permission .value {
  display: table-cell;
  padding: 0.3rem 0;
  font-family: monaco, "lucida console", monospace;
  font-size: 0.8rem;
}
.profile-component .permissions .permission .value {
  padding-left: 1rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvUHJvZmlsZSIsInNvdXJjZXMiOlsiUHJvZmlsZS5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0U7O0FBRUE7RUFDRTs7QUFFQTtBQUFBO0VBRUU7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5wcm9maWxlLWNvbXBvbmVudCB7XG4gIC5wZXJtaXNzaW9ucyB7XG4gICAgZGlzcGxheTogdGFibGU7XG5cbiAgICAucGVybWlzc2lvbiB7XG4gICAgICBkaXNwbGF5OiB0YWJsZS1yb3c7XG5cbiAgICAgIC5uYW1lLFxuICAgICAgLnZhbHVlIHtcbiAgICAgICAgZGlzcGxheTogdGFibGUtY2VsbDtcbiAgICAgICAgcGFkZGluZzogMC4zcmVtIDA7XG4gICAgICAgIGZvbnQtZmFtaWx5OiBtb25hY28sICdsdWNpZGEgY29uc29sZScsIG1vbm9zcGFjZTtcbiAgICAgICAgZm9udC1zaXplOiAwLjhyZW07XG4gICAgICB9XG5cbiAgICAgIC52YWx1ZSB7XG4gICAgICAgIHBhZGRpbmctbGVmdDogMXJlbTtcbiAgICAgIH1cbiAgICB9XG4gIH1cbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
