import React from 'react';
import { connect } from 'react-redux';

import EmptyState from '../EmptyState';
import SectionHeader from '../SectionHeader';
import Entity from '../Entity';

const getEntityName = (type, entity) => {
  if (type === 'groups' && entity) {
    return entity.path;
  }

  if (type === 'users' && entity) {
    return entity.name;
  }

  return '-';
};

class ConnectedTable extends React.Component {
  render() {
    const { eid, connected, users, groups } = this.props;

    return (
      <div className="connected-table">
        <SectionHeader title="Connected" />

        {connected.length ? (
          <table>
            <thead>
              <tr>
                <th>Entity</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {connected.map((connection) => {
                const [connectionType, connectionEid] = connection.split('/');
                return (
                  <tr key={`connected-${connection}`}>
                    <td>
                      <Entity eref={connection} />
                    </td>
                    <td>
                      {getEntityName(connectionType, users[connectionEid] || groups[connectionEid])}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <EmptyState>No entities connected to {eid}.</EmptyState>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.ad.users,
    groups: state.ad.groups,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedTable);
