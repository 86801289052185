function encodeScope(scope) {
  return `(${Object.entries(scope)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map((a) => a.join(':'))
    .join(',')})`;
}

function decodeScope(scopeString) {
  return scopeString
    .slice(1, -1)
    .split(',')
    .map((v) => v.split(':'))
    .reduce((acc, [a, b]) => Object.assign(acc, { [a]: b }), {});
}

export { encodeScope, decodeScope };
