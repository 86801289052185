import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import app from './reducers';
import watchAll from './sagas';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const store = createStore(app, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(watchAll);

export default store;
