import React from 'react';
import { connect } from 'react-redux';

import {
  requestEntity,
  grantPermission,
  revokePermission,
  addConnection,
  removeConnection,
} from '../../bundles/entities';
import { requestUser, requestGroup } from '../../bundles/ad';
import PermissionTable from '../PermissionTable';
import EntityPermissionTable from '../EntityPermissionTable';
import ConnectionsTable from '../ConnectionsTable';
import ConnectedTable from '../ConnectedTable';
import PageHeader from '../PageHeader';
import BackLink from '../BackLink';

const entityDisplayName = (type, id, entity) => {
  if (type === 'groups' && entity) {
    const { path } = entity;

    return `${path} (${id})`;
  }

  if (type === 'users' && entity) {
    const { firstName, lastName, username } = entity;
    const name = firstName ? `${firstName} ${lastName}` : username;
    return `${name} (${id})`;
  }

  return id;
};

class EntityDetail extends React.Component {
  componentDidMount() {
    const { entity, etype, eid, user, group } = this.props;

    if (etype === 'users' && !user) {
      this.props.requestUser(eid);
    }
    if (etype === 'groups' && !group) {
      this.props.requestGroup(eid);
    }
    if (entity === null) {
      this.props.requestEntity(etype, eid);
    }
  }

  render() {
    const { entity, eid, etype, user, group } = this.props;

    if (entity === null) {
      return 'Loading ...';
    }

    return (
      <section>
        <BackLink
          title={`Back to "${etype}"`}
          to={`/entities/${etype}`}
        />

        <PageHeader title={entityDisplayName(etype, eid, user || group)} />

        <PermissionTable
          title={etype}
          etype={etype}
          eid={eid}
          permissions={entity.permissions}
        />

        <EntityPermissionTable
          etype={etype}
          eid={eid}
          permissions={entity.entityPermissions}
          revokePermission={this.props.revokePermission}
          grantPermission={this.props.grantPermission}
        />

        <ConnectionsTable
          title="Connections"
          etype={etype}
          eid={eid}
          connections={entity.connections || []}
          addConnection={this.props.addConnection}
          removeConnection={this.props.removeConnection}
        />

        <ConnectionsTable
          title="Azure AD connections"
          etype={etype}
          eid={eid}
          connections={entity.adConnections || []}
        />

        <ConnectedTable
          etype={etype}
          eid={eid}
          connected={entity.connected || []}
        />
      </section>
    );
  }
}
const mapStateToProps = (state, props) => {
  const { etype, eid } = props.match.params;
  const ref = `${etype}/${eid}`;
  return {
    eid,
    etype,
    ref,
    user: state.ad.users[eid],
    group: state.ad.groups[eid],
    entity: state.entities[ref] || null,
  };
};

const mapDispatchToProps = {
  requestEntity,
  grantPermission,
  revokePermission,
  addConnection,
  removeConnection,
  requestUser,
  requestGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityDetail);
