import React from 'react';
import BaseModal from 'react-modal';

const modalStyles = {
  slim: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      overflow: 'visible',
      transform: 'translate(-50%, -50%)',
    },
  },
};

class Modal extends React.Component {
  render() {
    const { isOpen, onClose, children, title, style } = this.props;
    return (
      <BaseModal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={modalStyles[style]}
        closeOnEscape
      >
        <section className="modal-header">
          <div className="title">{title}</div>
          <a
            className="close"
            onClick={() => {
              onClose();
            }}
            role="button"
          >
            &times;
          </a>
        </section>
        <section className="modal-content">{children}</section>
      </BaseModal>
    );
  }
}

export default Modal;
