/* eslint-disable default-param-last */
import { takeLatest, call, put } from 'redux-saga/effects';
import { ForbiddenError } from '@avtjs/avt-services';
import { getAccessToken } from '@avtjs/react-components';

import { createServiceClient } from '../services';
import { handleGlobalError } from './errors';

// Action types

const REQUEST_SCOPE = 'admin/scopes/REQUEST_SCOPE';
const RECEIVE_SCOPE = 'admin/scopes/RECEIVE_SCOPE';

const REQUEST_SCOPES = 'admin/scopes/REQUEST_SCOPES';
const RECEIVE_SCOPES = 'admin/scopes/RECEIVE_SCOPES';

// Action creators

export function requestScope(scopeRef) {
  return {
    type: REQUEST_SCOPE,
    scopeRef,
  };
}

export function receiveScope(scopeRef, scope) {
  return {
    type: RECEIVE_SCOPE,
    scope,
    scopeRef,
  };
}

export function requestScopes() {
  return {
    type: REQUEST_SCOPES,
  };
}

export function receiveScopes(scopes) {
  return {
    type: RECEIVE_SCOPES,
    scopes,
  };
}

// Initial state

const initialState = {
  scopes: null,
};

// Reducer

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_SCOPE: {
      const { scopeRef, scope } = action;

      return {
        ...state,
        [scopeRef]: scope,
      };
    }

    case RECEIVE_SCOPES: {
      const { scopes } = action;

      return {
        ...state,
        scopes,
      };
    }

    default: {
      return state;
    }
  }
}

// Sagas

function* doRequestScope(action) {
  try {
    const { scopeRef } = action;

    const token = yield call(getAccessToken, 'auth');
    const client = createServiceClient('auth', token);
    const scope = yield call([client.auth, client.auth.getScope], scopeRef);

    yield put(receiveScope(scopeRef, scope));
  } catch (err) {
    yield put(handleGlobalError(err));
  }
}

function* doRequestScopes() {
  try {
    const token = yield call(getAccessToken, 'auth');
    const client = createServiceClient('auth', token);
    const { values } = yield call([client.auth, client.auth.getScopes]);

    yield put(receiveScopes(values));
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveScopes([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

export const sagas = [
  takeLatest(REQUEST_SCOPE, doRequestScope),
  takeLatest(REQUEST_SCOPES, doRequestScopes),
];
