/* eslint-disable default-param-last */
import { takeLatest, call, put } from 'redux-saga/effects';
import { ForbiddenError } from '@avtjs/avt-services';
import { getAccessToken } from '@avtjs/react-components';

import {
  getSources,
  getSource,
  createSource,
  updateSource,
  deleteSource as removeSource,
} from '../services';

import { handleGlobalError } from './errors';

// Action types

const RESET_STATE = 'admin/sources/RESET_STATE';

const REQUEST_SOURCES = 'admin/sources/REQUEST_SOURCES';
const RECEIVE_SOURCES = 'admin/sources/RECEIVE_SOURCES';

const REQUEST_SOURCE = 'admin/sources/REQUEST_SOURCE';
const RECEIVE_SOURCE = 'admin/sources/RECEIVE_SOURCE';

const SUBMIT_SOURCE = 'admin/sources/SUBMIT_SOURCE';
const SUBMIT_SUCCESS = 'admin/sources/SUBMIT_SUCCESS';

const DELETE_SOURCE = 'admin/sources/DELETE_SOURCE';
const DELETE_SUCCESS = 'admin/sources/DELETE_SUCCESS';

// Action creators

export function resetSourceState() {
  return {
    type: RESET_STATE,
  };
}

export function requestSources() {
  return {
    type: REQUEST_SOURCES,
  };
}

export function receiveSources(sources) {
  return {
    type: RECEIVE_SOURCES,
    sources,
  };
}

export function requestSource(id) {
  return {
    type: REQUEST_SOURCE,
    id,
  };
}

export function receiveSource(source) {
  return {
    type: RECEIVE_SOURCE,
    source,
  };
}

export function submitSource(source) {
  return {
    type: SUBMIT_SOURCE,
    source,
  };
}

export function submitSuccess(id) {
  return {
    type: SUBMIT_SUCCESS,
    id,
  };
}

export function deleteSource(id) {
  return {
    type: DELETE_SOURCE,
    id,
  };
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  };
}

// Initial state
const initialState = {
  sourcesList: [],
  source: null,
  isLoading: false,
  submitSuccess: null,
  deleteSuccess: null,
};

// Reducer
export function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_STATE: {
      return {
        ...initialState,
      };
    }
    case REQUEST_SOURCES: {
      return {
        ...state,
        sources: [],
        isLoading: true,
      };
    }
    case REQUEST_SOURCE: {
      return {
        ...state,
        source: null,
        isLoading: true,
      };
    }
    case RECEIVE_SOURCES: {
      let sourcesList = [];
      if (action.sources.length) {
        sourcesList = action.sources;
      }
      return {
        ...state,
        sourcesList,
        isLoading: false,
      };
    }
    case RECEIVE_SOURCE: {
      const source = { ...action.source };
      if (!source.location) {
        source.location = {
          city: '',
          country: '',
          lat: 0,
          lng: 0,
        };
      }
      return {
        ...state,
        source,
        isLoading: false,
      };
    }
    case SUBMIT_SOURCE: {
      return {
        ...state,
        submitSuccess: null,
      };
    }
    case SUBMIT_SUCCESS: {
      return {
        ...state,
        submitSuccess: action.id,
      };
    }
    case DELETE_SOURCE: {
      return {
        ...state,
        deleteSuccess: false,
      };
    }
    case DELETE_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
      };
    }

    default: {
      return state;
    }
  }
}

// Sagas

function* doRequestSources() {
  try {
    const token = yield call(getAccessToken, 'auth');
    const { values } = yield getSources(token);
    yield put(receiveSources(values));
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveSources([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

function* doRequestSource({ id }) {
  try {
    const token = yield call(getAccessToken, 'auth');
    const source = yield getSource(token, id);
    yield put(receiveSource(source));
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveSources([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

function* doSubmitSource({ source }) {
  try {
    const token = yield call(getAccessToken, 'auth');
    let response;
    if (source.id) {
      response = yield updateSource(token, source);
    } else {
      response = yield createSource(token, source);
    }
    yield put(submitSuccess(response.id));
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveSources([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

function* doDeleteSource({ id }) {
  try {
    const token = yield call(getAccessToken, 'auth');
    yield removeSource(token, id);
    yield put(deleteSuccess());
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      yield put(receiveSources([]));
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

export const sagas = [
  takeLatest(REQUEST_SOURCES, doRequestSources),
  takeLatest(REQUEST_SOURCE, doRequestSource),
  takeLatest(SUBMIT_SOURCE, doSubmitSource),
  takeLatest(DELETE_SOURCE, doDeleteSource),
];
