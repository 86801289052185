import React from 'react';
import { Button } from '@avtjs/react-components';

import Modal from '../Modal';
import Autocomplete from '../Autocomplete';

class AddRoleModal extends React.Component {
  state = {
    value: '',
    roles: ['roles/Admin', 'roles/MEK'],
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        style="slim"
        onClose={this.props.onClickClose}
        title="Add connection"
      >
        <section className="add-role-modal">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.onClickAdd(this.state.value);
            }}
          >
            <div className="field">
              <Autocomplete
                getItemValue={(item) => item}
                inputProps={{
                  placeholder: 'Enter or select an entity ...',
                  required: true,
                }}
                shouldItemRender={(item, value) =>
                  item.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                items={this.state.roles}
                value={this.state.value}
                onChange={(e) => this.setState({ value: e.target.value })}
                onSelect={(value) => this.setState({ value })}
              />
            </div>
            <div className="submit">
              <Button type="submit">Add connection</Button>
            </div>
          </form>
        </section>
      </Modal>
    );
  }
}

export default AddRoleModal;
