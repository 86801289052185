import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@avtjs/react-components';

import { resetSourceState, requestSource } from '../../bundles/sources';
import { requestComponents, getComponentsBySource } from '../../bundles/components';

import BackLink from '../BackLink';
import PageHeader from '../PageHeader';
import ComponentList from '../ComponentList';

import SourceModal from '../SourceModal';

const SourceDetail = ({
  id,
  isLoading,
  source,
  resetSourceState: doResetSourceState,
  requestSource: getSource,
  requestComponents: getComponents,
  components,
}) => {
  const [modalEditOpen, setModalEditOpen] = useState(false);

  useEffect(() => {
    doResetSourceState();
    getSource(id);
    getComponents({ source: id });
  }, [id]);

  if (isLoading) {
    return 'Loading...';
  }

  if (!id || source === null) {
    return (
      <section>
        <BackLink
          title={'Back to sources'}
          to={'/sources'}
        />
        <PageHeader title={'404 - Source not found'} />
      </section>
    );
  }

  const refreshDetails = () => {
    setTimeout(() => {
      doResetSourceState();
      getSource(id);
    }, 200);
  };

  const onEditSuccess = () => {
    setModalEditOpen(false);
    refreshDetails();
  };

  return (
    <section>
      <BackLink
        title={'Back to sources'}
        to={'/sources'}
      />
      <div
        className="row"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <div className="cell"></div>
        <div className="cell">
          <Button
            activity="info"
            onClick={() => {
              setModalEditOpen(true);
            }}
          >
            Edit source
          </Button>
        </div>
      </div>
      {modalEditOpen && (
        <SourceModal
          data={source}
          onClose={() => setModalEditOpen(false)}
          onSubmitSuccess={onEditSuccess}
        />
      )}

      <div className="source-detail">
        <table>
          <tbody>
            {Object.keys(source).map((key) => (
              <tr key={key}>
                <td>
                  {' '}
                  <strong> {key} </strong>{' '}
                </td>
                <td>{JSON.stringify(source[key])}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <ComponentList
          components={components}
          baseComponent={{ source: id }}
        />
      </div>
    </section>
  );
};

const mapStateToProps = (state, { match: { params } }) => {
  const {
    sources: { isLoading, source },
  } = state;
  const components = getComponentsBySource(state, params.id);
  return {
    id: params && params.id ? params.id : undefined,
    source,
    isLoading,
    components,
  };
};

const mapDispatchToProps = {
  requestSource,
  requestComponents,
  resetSourceState,
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceDetail);
