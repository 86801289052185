import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Redirect, Switch } from 'react-router-dom';

import { logger, useAuth, AuthProvider } from '@avtjs/react-components';

import { createBrowserHistory } from 'history';

import config from '../../config';
import store from '../../store';

import Header from '../Header';
import Profile from '../Profile';
import MainContent from '../MainContent';
import AppFrame from '../AppFrame';
import EntityList from '../EntityList';
import EntityDetail from '../EntityDetail';
import SystemMessageList from '../SystemMessageList';
import SiteList from '../SiteList';
import SiteDetail from '../SiteDetail';
import ScopeList from '../ScopeList';
import SourceList from '../SourceList';
import SourceDetail from '../SourceDetail';
import ScopeDetail from '../ScopeDetail';

const authConfig = {
  ...config.auth,
};

const authInitConfig = {
  pkceMethod: 'S256',
  enableLogging: true,
};

const history = createBrowserHistory();

logger.init(
  {
    ...config.applicationInsights,
    connectionString: config.applicationInsights.connectionString,
  },
  { browserHistory: history }
);

const App = () => {
  const { authenticated, subject, logOut } = useAuth();

  useEffect(() => {
    if (authenticated) {
      logger.setUser(subject);
    } else {
      logger.setUser(null);
    }
  }, [authenticated]);

  if (!authenticated) {
    return <div>Authentication required.</div>;
  }

  return (
    <Provider store={store}>
      <AppFrame>
        <Router history={history}>
          <section>
            <Header
              logout={logOut}
              user={subject}
            />
            <MainContent>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => <Redirect to="/entities/users/" />}
                />
                <Route
                  exact
                  path="/entities/:etype"
                  render={(props) => (
                    <EntityList
                      key={props.match.params.etype}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/entities/:etype/:eid"
                  render={(props) => (
                    <EntityDetail
                      key={`${props.match.params.etype}/${props.match.params.eid}`}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/sites"
                  component={SiteList}
                />
                <Route
                  exact
                  path="/site/:id"
                  component={SiteDetail}
                />
                <Route
                  exact
                  path="/scopes"
                  component={ScopeList}
                />
                <Route
                  exact
                  path="/sources"
                  component={SourceList}
                />
                <Route
                  exact
                  path="/sources/:id"
                  component={SourceDetail}
                />
                <Route
                  exact
                  path="/system-messages"
                  component={SystemMessageList}
                />
                <Route
                  exact
                  path="/scopes/:scopeRef"
                  render={(props) => (
                    <ScopeDetail
                      key={`${props.match.params.scopeRef}`}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/profile/"
                  component={Profile}
                />
              </Switch>
            </MainContent>
          </section>
        </Router>
      </AppFrame>
    </Provider>
  );
};

export default (props) => (
  <AuthProvider
    config={authConfig}
    initConfig={authInitConfig}
  >
    <App {...props} />
  </AuthProvider>
);
