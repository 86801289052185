import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { resetSiteState, requestSites } from '../../bundles/sites';
import SearchForm from '../SearchForm';
import SiteTable from '../SiteTable';
import PageHeader from '../PageHeader';

const SiteList = ({
  etype,
  sites,
  sitesIsLoading,
  resetSiteState: resetState,
  requestSites: getSites,
}) => {
  useEffect(() => {
    getSites(etype);
  }, [etype]);

  if (sitesIsLoading) {
    return 'Loading ...';
  }

  const onDelete = () => {
    setTimeout(() => {
      resetState();
      getSites(etype);
    }, 300);
  };

  return (
    <section>
      <PageHeader title={'Sites'}>
        <SearchForm
          placeHolder="Site id ..."
          redirectUri={(term) => `/site/${term}`}
        />
      </PageHeader>
      <SiteTable
        sites={sites}
        onDelete={onDelete}
      />
    </section>
  );
};

function mapStateToProps({ sites }) {
  return {
    sites: sites.sitesList,
    sitesIsLoading: sites.isLoading,
  };
}

const mapDispatchToProps = {
  requestSites,
  resetSiteState,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteList);
