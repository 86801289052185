import React from 'react';
import classnames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import { withRouter } from 'react-router-dom';

class DropdownMenu extends React.Component {
  state = {
    open: false,
  };

  componentDidMount() {
    const { history } = this.props;
    this.unlisten = history.listen(() => {
      this.setState({ open: false });
    });
  }

  componentDidUnMount() {
    this.unlisten();
  }

  handleClickOutside = () => {
    this.setState({ open: false });
  };

  toggle = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  render() {
    const { open } = this.state;

    const classes = classnames(this.props.className, 'dropdown-component', {
      open,
    });

    const Header = React.isValidElement(this.props.header) ? (
      this.props.header
    ) : (
      <div className="title">{this.props.header}</div>
    );

    return (
      <div className={classes}>
        <div
          className="header"
          onClick={this.toggle}
        >
          {Header}
        </div>
        <div className="content">{this.props.children}</div>
      </div>
    );
  }
}

export default withRouter(onClickOutside(DropdownMenu));
