import React from 'react';
import { connect } from 'react-redux';

import { requestScopes } from '../../bundles/scopes';
import ScopeTable from '../ScopeTable';
import PageHeader from '../PageHeader';

class ScopeList extends React.Component {
  componentDidMount() {
    this.props.requestScopes();
  }

  render() {
    const { scopes } = this.props;

    if (scopes === null) {
      return 'Loading...';
    }

    return (
      <div>
        <PageHeader title="Scopes" />

        <ScopeTable scopes={scopes} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  url: props.match.url,
  scopes: state.scopes.scopes,
});

const mapDispatchToProps = {
  requestScopes,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScopeList);
