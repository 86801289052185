/* eslint-disable default-param-last */
import { takeLatest, call, put } from 'redux-saga/effects';
import { ForbiddenError } from '@avtjs/avt-services';
import { getAccessToken } from '@avtjs/react-components';

import { updateProperty, deleteProperty } from '../services';

import { handleGlobalError } from './errors';

// Action types

const RESET_STATE = 'admin/properties/RESET_STATE';

const SUBMIT_PROPERTY = 'admin/properties/SUBMIT_PROPERTY';
const SUBMIT_SUCCESS = 'admin/properties/SUBMIT_SUCCESS';

const DELETE_PROPERTY = 'admin/properties/DELETE_PROPERTY';
const DELETE_SUCCESS = 'admin/properties/DELETE_SUCCESS';

// Action creators

export function resetPropertysState() {
  return {
    type: RESET_STATE,
  };
}

export function submitProperty(siteId, data) {
  return {
    type: SUBMIT_PROPERTY,
    siteId,
    data,
  };
}

export function submitSuccess(id) {
  return {
    type: SUBMIT_SUCCESS,
    id,
  };
}

export function removeProperty(siteId, propertyId) {
  return {
    type: DELETE_PROPERTY,
    siteId,
    propertyId,
  };
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  };
}

// Initial state

const initialState = {
  submitSuccess: null,
  deleteSuccess: null,
};

// Reducer

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_STATE: {
      return {
        ...initialState,
      };
    }
    case SUBMIT_PROPERTY: {
      return {
        ...state,
        submitSuccess: null,
      };
    }
    case SUBMIT_SUCCESS: {
      return {
        ...state,
        submitSuccess: action.id,
      };
    }
    case DELETE_PROPERTY: {
      return {
        ...state,
        deleteSuccess: false,
      };
    }
    case DELETE_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
      };
    }

    default: {
      return state;
    }
  }
}

// Sagas

function* doSubmitProperty({ siteId, data }) {
  try {
    if (siteId && data) {
      const token = yield call(getAccessToken);
      const response = yield updateProperty(token, siteId, data);
      yield put(submitSuccess(response ? response.id : true));
    }
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      console.warn(err);
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

function* doDeleteProperty({ siteId, propertyId }) {
  try {
    const token = yield call(getAccessToken, 'auth');
    yield deleteProperty(token, siteId, propertyId);
    yield put(deleteSuccess());
  } catch (err) {
    if (err.constructor === ForbiddenError) {
      console.warn(err);
    } else {
      yield put(handleGlobalError(err));
    }
  }
}

export const sagas = [
  takeLatest(SUBMIT_PROPERTY, doSubmitProperty),
  takeLatest(DELETE_PROPERTY, doDeleteProperty),
];
