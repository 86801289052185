import React from 'react';

class Permission extends React.Component {
  render() {
    const { name } = this.props;

    return <span className="permission">{name}</span>;
  }
}

export default Permission;
