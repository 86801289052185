// eslint-disable-next-line import/no-unresolved
import { createClient } from '@avtjs/avt-services';
import { getAccessToken } from '@avtjs/react-components';

import config from './config';

function createServiceClient(serviceName, token) {
  const serviceConfig = { ...config.services };
  serviceConfig[serviceName] = serviceConfig[serviceName] || {};
  serviceConfig[serviceName].onRequestToken = token ? () => token : getAccessToken;
  return createClient(serviceConfig);
}

const services = createClient({
  ...config.services,
  onRequestToken: getAccessToken,
});

const createVariable = (token, data) => {
  services.data.config.onRequestToken = () => token;
  return services.data.createVariable(data);
};

const updateVariable = (token, componentId, data) => {
  services.data.config.onRequestToken = () => token;
  return services.data.updateVariable(componentId, data);
};

const deleteVariable = (token, componentId, variableId) => {
  services.data.config.onRequestToken = () => token;
  return services.data.deleteVariable(componentId, variableId);
};

const getComponents = (token, filter) => {
  services.data.config.onRequestToken = () => token;
  return services.data.getComponents(filter);
};

const createComponent = (token, component) => {
  services.data.config.onRequestToken = () => token;
  return services.data.createComponent(component);
};

const updateComponent = (token, component) => {
  services.data.config.onRequestToken = () => token;
  return services.data.updateComponent(component.id, component);
};

const deleteComponent = (token, componentId) => {
  services.data.config.onRequestToken = () => token;
  return services.data.deleteComponent(componentId);
};

const getSources = (token) => {
  services.data.config.onRequestToken = () => token;
  return services.data.getSources();
};

const getSource = (token, id) => {
  services.data.config.onRequestToken = () => token;
  return services.data.getOneSource(id);
};

const createSource = (token, source) => {
  services.data.config.onRequestToken = () => token;
  return services.data.createSource(source);
};

const updateSource = (token, source) => {
  services.data.config.onRequestToken = () => token;
  return services.data.updateSource(source.id, source);
};

const deleteSource = (token, id) => {
  services.data.config.onRequestToken = () => token;
  return services.data.deleteSource(id);
};

const getSites = (token) => {
  services.site.config.onRequestToken = () => token;
  return services.site.getSites();
};

const getSite = (token, id) => {
  services.site.config.onRequestToken = () => token;
  return services.site.getSite(id);
};

const createSite = (token, site) => {
  services.site.config.onRequestToken = () => token;
  return services.site.createSite(site);
};

const updateSite = (token, site) => {
  services.site.config.onRequestToken = () => token;
  return services.site.updateSite(site.id, site);
};

const deleteSite = (token, id) => {
  services.site.config.onRequestToken = () => token;
  return services.site.deleteSite(id);
};

const getModels = (token) => {
  services.model.config.onRequestToken = () => token;
  return services.model.getModels();
};

const addProperty = (token, siteId, data) => {
  services.site.config.onRequestToken = () => token;
  return services.site.addProperty(siteId, data);
};

const updateProperty = (token, siteId, data) => {
  services.site.config.onRequestToken = () => token;
  return services.site.updateProperty(siteId, data.attribute, data);
};

const deleteProperty = (token, siteId, propertyId) => {
  services.site.config.onRequestToken = () => token;
  return services.site.deleteProperty(siteId, propertyId);
};

const addLayout = (token, siteId, data) => {
  services.site.config.onRequestToken = () => token;
  return services.site.addLayout(siteId, data);
};

const updateLayout = (token, siteId, data) => {
  services.site.config.onRequestToken = () => token;
  return services.site.updateLayout(siteId, data.id, data);
};

const deleteLayout = (token, siteId, layoutId) => {
  services.site.config.onRequestToken = () => token;
  return services.site.deleteLayout(siteId, layoutId);
};

const getUser = (token, userId) => {
  services.auth.config.onRequestToken = () => token;
  return services.auth.getOneUser(userId);
};

const getGroup = (token, id) => {
  services.auth.config.onRequestToken = () => token;
  return services.auth.getOneGroup(id);
};

const getSystemMessages = (token) => {
  services.collaboration.config.onRequestToken = () => token;
  return services.collaboration.getSystemMessages();
};

const createSystemMessage = (token, systemMessage) => {
  services.collaboration.config.onRequestToken = () => token;
  return services.collaboration.createSystemMessage(systemMessage);
};

const deleteSystemMessage = (token, id) => {
  services.collaboration.config.onRequestToken = () => token;
  return services.collaboration.deleteSystemMessage(id);
};

export {
  createServiceClient,
  updateVariable,
  deleteVariable,
  createVariable,
  getComponents,
  createComponent,
  updateComponent,
  deleteComponent,
  getSources,
  getSource,
  createSource,
  updateSource,
  deleteSource,
  getSites,
  getSite,
  createSite,
  updateSite,
  deleteSite,
  getModels,
  addProperty,
  updateProperty,
  deleteProperty,
  addLayout,
  updateLayout,
  deleteLayout,
  getUser,
  getGroup,
  getSystemMessages,
  createSystemMessage,
  deleteSystemMessage,
};
