import { combineReducers } from 'redux';

import { reducer as auth } from './bundles/auth';
import { reducer as errors } from './bundles/errors';
import { reducer as entities } from './bundles/entities';
import { reducer as scopes } from './bundles/scopes';
import { reducer as ad } from './bundles/ad';
import { reducer as sites } from './bundles/sites';
import { reducer as sources } from './bundles/sources';
import { reducer as components } from './bundles/components';
import { reducer as models } from './bundles/models';
import { reducer as layouts } from './bundles/layouts';
import { reducer as properties } from './bundles/properties';
import { reducer as systemMessages } from './bundles/systemMessages';

const reducers = combineReducers({
  auth,
  errors,
  entities,
  scopes,
  ad,
  sites,
  sources,
  components,
  models,
  layouts,
  properties,
  systemMessages,
});

export default reducers;
