import React from 'react';
import { connect } from 'react-redux';

import Permission from '../Permission';
import Entity from '../Entity';

class ScopeGrantsTable extends React.Component {
  render() {
    const { grants, users } = this.props;

    return (
      <section>
        <table>
          <thead>
            <tr>
              <th>Entity</th>
              <th>Name</th>
              <th>Permission</th>
            </tr>
          </thead>
          <tbody>
            {grants.map(({ entityRef, permission }) => {
              const eid = entityRef.split('/')[1];
              return (
                <tr key={`${entityRef}-${permission}`}>
                  <td>
                    <Entity eref={entityRef} />
                  </td>
                  <td>{users[eid] ? users[eid].displayName : '-'}</td>
                  <td className="permission-name">
                    <Permission name={permission} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.ad.users,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ScopeGrantsTable);
