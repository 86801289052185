import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Can } from '@avtjs/react-components';

import { Link } from 'react-router-dom';
import { deleteSite } from '../../bundles/sites';

import EmptyState from '../EmptyState';
import SectionHeader from '../SectionHeader';
import ComponentModal from '../ComponentModal';
import VariableModal from '../VariableModal';

const ComponentList = ({
  components,
  baseComponent,
  submitSuccess,
  deleteSuccess,
  deleteVariableSuccess,
  submitVariableSuccess,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [variableModalOpen, setVariableModalOpen] = useState(false);
  const [variableModalRemoveId, setVariableModalRemoveId] = useState(false);
  const [modalRemoveId, setModalRemoveId] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [displayVariables, setDisplayVariables] = useState(false);
  const [baseVariable, setBaseVariable] = useState(false);

  useEffect(() => {
    setModalOpen(false);
    setModalRemoveId(false);
  }, [deleteSuccess, submitSuccess]);

  useEffect(() => {
    setVariableModalOpen(false);
    setVariableModalRemoveId(false);
  }, [submitVariableSuccess, deleteVariableSuccess]);

  return (
    <section className="components-table">
      <SectionHeader title="Components">
        <Can permission="permissions/Write">
          <Button
            activity="info"
            onClick={() => {
              setModalEdit(null);
              setModalOpen(true);
            }}
          >
            Add component
          </Button>
        </Can>
      </SectionHeader>
      {components.length ? (
        <table>
          <thead>
            <tr>
              <th>Item designation</th>
              <th>Organization</th>
              <th>Variables</th>
              <th>Site</th>
              <th>Source</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {components.map((component) => (
              <React.Fragment key={component.id}>
                <tr
                  key={component.id}
                  id={`component-${component.id}`}
                >
                  <td>{component.itemDesignation}</td>
                  <td>{component.org}</td>
                  <td>
                    <a
                      href={`#component-${component.id}`}
                      onClick={() => {
                        if (displayVariables === component.id) {
                          setDisplayVariables(false);
                        } else {
                          setDisplayVariables(component.id);
                        }
                      }}
                    >
                      {component.variables.length}
                    </a>
                  </td>
                  <td>
                    <Link to={`/site/${component.site}`}>{component.site}</Link>
                  </td>
                  <td>
                    <Link to={`/sources/${component.source}`}>{component.source}</Link>
                  </td>
                  <td className="actions">
                    <Button
                      activity="primary"
                      onClick={() => {
                        setModalOpen(true);
                        setModalEdit({ ...component, variables: undefined });
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      activity="danger"
                      onClick={() => {
                        setModalOpen(true);
                        setModalRemoveId(component.id);
                      }}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>

                {displayVariables === component.id && (
                  <tr key={`${component.id}-variables`}>
                    <td
                      className="variable-panel"
                      colSpan="6"
                    >
                      <div className="variable-panel-header">
                        <h3>Variables </h3>
                        <Button
                          activity={'info'}
                          onClick={() => {
                            setVariableModalOpen(true);
                            setBaseVariable({ componentId: component.id });
                          }}
                        >
                          Add variable
                        </Button>
                      </div>
                      <table>
                        <thead>
                          <tr>
                            <th> Name </th>
                            <th> Type </th>
                            <th> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {component.variables.map((variable) => (
                            <tr
                              className="variable-row"
                              key={variable.id}
                            >
                              <td>{variable.name}</td>
                              <td>{variable.type || variable.dataType}</td>
                              <td
                                align="right"
                                className="actions"
                              >
                                <Button
                                  activity="info"
                                  onClick={() => {
                                    setVariableModalOpen(true);
                                    setBaseVariable({
                                      ...variable,
                                      componentId: component.id,
                                    });
                                  }}
                                >
                                  Edit
                                </Button>

                                <Button
                                  activity="danger"
                                  onClick={() => {
                                    setVariableModalOpen(true);
                                    setVariableModalRemoveId({
                                      componentId: component.id,
                                      variableId: variable.id,
                                    });
                                  }}
                                >
                                  Remove
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <EmptyState>Nothing to display.</EmptyState>
      )}
      {modalOpen && (
        <ComponentModal
          data={modalEdit}
          remove={modalRemoveId}
          baseComponent={baseComponent}
          onClose={() => setModalOpen(false)}
          onRemoveSuccess={() => {
            setModalOpen(false);
            setModalRemoveId(false);
          }}
          onSubmitSuccess={() => setModalOpen(false)}
        />
      )}
      {variableModalOpen && (
        <VariableModal
          remove={variableModalRemoveId}
          baseVariable={baseVariable}
          onClose={() => setVariableModalOpen(false)}
          onSubmitSuccess={() => setVariableModalOpen(false)}
        />
      )}
    </section>
  );
};

function mapStateToProps({
  errors: { error },
  components: { deleteVariableSuccess, deleteSuccess, submitSuccess, submitVariableSuccess },
}) {
  let message = '';
  if (error && error.name) {
    message = error.name;
  } else if (error && error.object && error.object.message) {
    // eslint-disable-next-line
    message = error.object.message;
  }
  return {
    submitSuccess,
    deleteSuccess,
    submitVariableSuccess,
    deleteVariableSuccess,
    error: message,
  };
}

const mapDispatchToProps = {
  deleteSite,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentList);
