const css = `.site-model-selector {
  max-width: 80vw;
  min-width: 25rem;
}
.site-model-selector .model-selector-message {
  font-size: 0.8rem;
  color: #888;
}
.site-model-selector .model-selector-input-spacer {
  margin-bottom: 1rem;
}
.site-model-selector select {
  background: white;
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem;
  font-size: 1em;
  border-radius: 0.2rem;
  border: 1px solid #cccccc;
  width: 100%;
}
.site-model-selector select:invalid {
  box-shadow: none;
}
.site-model-selector select:disabled {
  pointer-events: none;
  opacity: 0.4;
  background: lightgrey;
}
.site-model-selector button:disabled, .site-model-selector button.disabled {
  pointer-events: none;
  background: grey;
  border-color: lightgrey;
  opacity: 0.4;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZU1vZGVsU2VsZWN0b3IiLCJzb3VyY2VzIjpbIlNpdGVNb2RlbFNlbGVjdG9yLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTtFQUNBOztBQUVBO0VBQ0U7RUFDQTs7QUFHRjtFQUNFOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFOztBQUVGO0VBQ0U7RUFDQTtFQUNBOztBQUtGO0VBRUU7RUFDQTtFQUNBO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IFwiQGF2dGpzL3JlYWN0LWNvbXBvbmVudHMvZGlzdC9jb2xvcnMuc2Nzc1wiO1xuXG4uc2l0ZS1tb2RlbC1zZWxlY3RvciB7XG4gIG1heC13aWR0aDogODB2dztcbiAgbWluLXdpZHRoOiAyNXJlbTtcblxuICAubW9kZWwtc2VsZWN0b3ItbWVzc2FnZSB7XG4gICAgZm9udC1zaXplOiAwLjhyZW07XG4gICAgY29sb3I6ICM4ODg7XG4gIH1cblxuICAubW9kZWwtc2VsZWN0b3ItaW5wdXQtc3BhY2VyIHtcbiAgICBtYXJnaW4tYm90dG9tOiAxcmVtO1xuICB9XG5cbiAgc2VsZWN0IHtcbiAgICBiYWNrZ3JvdW5kOiB3aGl0ZTtcbiAgICBib3gtc2l6aW5nOiBib3JkZXItYm94O1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIHBhZGRpbmc6IDAuNXJlbTtcbiAgICBmb250LXNpemU6IDFlbTtcbiAgICBib3JkZXItcmFkaXVzOiAwLjJyZW07XG4gICAgYm9yZGVyOiAxcHggc29saWQgJEdyZXkyMDtcbiAgICB3aWR0aDogMTAwJTtcblxuICAgICY6aW52YWxpZCB7XG4gICAgICBib3gtc2hhZG93OiBub25lO1xuICAgIH1cbiAgICAmOmRpc2FibGVkIHtcbiAgICAgIHBvaW50ZXItZXZlbnRzOiBub25lO1xuICAgICAgb3BhY2l0eTogMC40O1xuICAgICAgYmFja2dyb3VuZDogbGlnaHRncmV5O1xuICAgIH1cbiAgfVxuXG4gIGJ1dHRvbiB7XG4gICAgJjpkaXNhYmxlZCxcbiAgICAmLmRpc2FibGVkIHtcbiAgICAgIHBvaW50ZXItZXZlbnRzOiBub25lO1xuICAgICAgYmFja2dyb3VuZDogZ3JleTtcbiAgICAgIGJvcmRlci1jb2xvcjogbGlnaHRncmV5O1xuICAgICAgb3BhY2l0eTogMC40O1xuICAgIH1cbiAgfVxufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
